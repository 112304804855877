





































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AddCustomInvestorGoalModalInitViewModel
  from '@/vue-app/view-models/components/goals-dashboard/add-goals/add-custom-investor-goal-modal-init-view-model';

@Component({
  name: 'AddCustomInvestorGoalModalInit',
  components: {
    AddCustomInvestorGoalWizardModal: () => import('@/vue-app/components/goals-dashboard/add-goals/AddCustomInvestorGoalWizardModal.vue'),
    AddCustomInvestorGoalModalSuccess: () => import('@/vue-app/components/goals-dashboard/add-goals/AddCustomInvestorGoalModalSuccess.vue'),
    SuccessCreatedAndLinkedGoalModal: () => import('@/vue-app/components/goals-dashboard/add-goals/link/SuccessCreatedAndLinkedGoalModal.vue'),
  },
})
export default class AddCustomInvestorGoalModalInit extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  add_custom_investor_goal_modal_model = Vue.observable(
    new AddCustomInvestorGoalModalInitViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  showAddCustomInvestorGoalWizard() {
    this.synced_is_open = false;
    this.add_custom_investor_goal_modal_model.showAddCustomInvestorGoalWizard();
  }
}
