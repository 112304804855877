import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AddCustomInvestorGoalModalInitViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.add-goals.add-custom-investor-goal-modal-init';

  show_add_custom_investor_goal_wizard = false;

  show_add_custom_investor_goal_success = false;

  show_success_created_and_linked_goal_modal = false;

  product_name = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  showAddCustomInvestorGoalWizard = () => {
    this.show_add_custom_investor_goal_wizard = true;
  }

  createdGoal = () => {
    this.show_add_custom_investor_goal_success = true;
  }

  createdAndLinkedGoal = (product_name: string) => {
    this.product_name = product_name;
    this.show_success_created_and_linked_goal_modal = true;
  }
}
